export var MANAGE_VIDEOS = 'App_Manager_Wix_Video_Main_CTA';
export var ADD_VIDEO_ELEMENT = 'App_Manager_Wix_Video_Quick_Action_1';
export var MANAGE_CHANNELS = 'App_Manager_Wix_Video_Quick_Action_3';
export var LIVE_STREAM = 'App_Manager_Wix_Video_Quick_Action_4';
export var OPEN_VIDEO_MANAGER_IN_BM = 'openVideoManagerInBM';
export var OPEN_VIDEO_SECTION_IN_ADD_PANEL = 'openVideoSectionInAddPanel';
export var OPEN_CHANNELS_TAB = 'openChannelsTab';
export var OPEN_LIVE_STREAM_TAB = 'openLiveStreamTab';
export var VIDEOS_SECTION = "/video-library/videos";
export var LIVE_STREAM_SECTION = "/video-library/live-videos";
export var CHANNELS_SECTION = "/video-library/channels";
export var UPGRADE_TEXT = 'App_Manager_Wix_Video_Upgrade_Text';
export var UPGRADE_LINK_TEXT = 'App_Manager_Wix_Video_Upgrade_Link';
export var PAID_PLANS_APP_DEF_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';
