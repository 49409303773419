import { __awaiter, __generator } from "tslib";
import { VIDEO_APP_DEF_ID } from '@wix/wix-vod-constants/dist/common/app-def-id';
import { OPEN_VIDEO_MANAGER_IN_BM, OPEN_LIVE_STREAM_TAB, OPEN_CHANNELS_TAB, OPEN_VIDEO_SECTION_IN_ADD_PANEL, VIDEOS_SECTION, LIVE_STREAM_SECTION, CHANNELS_SECTION, PAID_PLANS_APP_DEF_ID, } from './constants';
import { getWixVideoAppManifest } from './appManifest';
import { getTranslateFunction } from './i18n';
var translate, token;
var app = {
    editorReady: function (editorSDK, _token, _a) {
        var initialAppData = _a.initialAppData;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, getTranslateFunction(initialAppData.languageCode)];
                    case 1:
                        translate = _b.sent();
                        token = _token;
                        return [2 /*return*/];
                }
            });
        });
    },
    onEvent: function (_a, _b) {
        var eventType = _a.eventType, eventPayload = _a.eventPayload;
        var editor = _b.editor;
        return __awaiter(void 0, void 0, void 0, function () {
            var show;
            return __generator(this, function (_c) {
                show = editor.deeplink.show;
                try {
                    switch (eventPayload.actionId) {
                        case OPEN_VIDEO_MANAGER_IN_BM:
                            editor.openDashboardPanel(VIDEO_APP_DEF_ID, { url: VIDEOS_SECTION });
                            break;
                        case OPEN_VIDEO_SECTION_IN_ADD_PANEL:
                            show('', {
                                type: 'addPanel',
                                named: {
                                    appDefinitionId: VIDEO_APP_DEF_ID,
                                },
                            });
                            break;
                        case OPEN_LIVE_STREAM_TAB:
                            editor.openDashboardPanel(VIDEO_APP_DEF_ID, {
                                url: LIVE_STREAM_SECTION,
                            });
                            break;
                        case OPEN_CHANNELS_TAB:
                            editor.openDashboardPanel(VIDEO_APP_DEF_ID, {
                                url: CHANNELS_SECTION,
                            });
                            break;
                        default:
                            return [2 /*return*/];
                    }
                }
                catch (e) {
                    console.log('EditorScript ---> OnEvent Error');
                }
                return [2 /*return*/];
            });
        });
    },
    getAppManifest: function () { return getWixVideoAppManifest(translate); },
    handleAction: function (_a, sdk) {
        var type = _a.type, payload = _a.payload;
        return __awaiter(void 0, void 0, void 0, function () {
            var _b;
            return __generator(this, function (_c) {
                if (type === 'migrate' && ((_b = payload) === null || _b === void 0 ? void 0 : _b.addPaidPlans)) {
                    return [2 /*return*/, sdk.document.tpa.add.application(token, {
                            appDefinitionId: PAID_PLANS_APP_DEF_ID,
                        })];
                }
                return [2 /*return*/];
            });
        });
    },
};
export var editorReady = app.editorReady;
export var handleAction = app.handleAction;
export var onEvent = app.onEvent;
export var getAppManifest = app.getAppManifest;
