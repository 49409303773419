import { __awaiter, __generator } from "tslib";
import i18next from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';
import { GET_PUBLIC_PATH } from 'services/locale/config';
var i18nextCore = function (locale, cb) { return i18next.use(i18nextXHRBackend).init({
    lng: locale,
    fallbackLng: 'en',
    keySeparator: '$',
    interpolation: {
        escapeValue: false,
    },
    backend: {
        loadPath: GET_PUBLIC_PATH() + "static/locales/app-manager/{{lng}}.json",
        crossDomain: true,
    },
}, cb); };
var i18n = function (locale) {
    return new Promise(function (resolve, reject) {
        var i18nInstance = i18nextCore(locale, function (err, t) { return err ? reject(err) : resolve({ t: t, i18nInstance: i18nInstance }); });
    });
};
export var getTranslateFunction = function (locale) { return __awaiter(void 0, void 0, void 0, function () {
    var t;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, i18n(locale)];
            case 1:
                t = (_a.sent()).t;
                return [2 /*return*/, t];
        }
    });
}); };
